<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h3>Listado de pedidos </h3>

        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        />
      </b-row>

    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="getOrders"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Sin resultados de búsqueda"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #head(invoiceStatus)>
        <feather-icon
          icon="TrendingUpIcon"
          class="mx-auto"
        />
      </template>

      <!-- Column: Id -->
      <template #cell(number)="data">
        <b-link
          :to="{ name: 'orders-show', params: { id: data.item.id }}"
          class="font-weight-bold"
        >
          #{{ data.item.number }}
        </b-link>
      </template>

      <!-- Column: Client -->
      <template #cell(shippingAddress)="data">
        <span class="text-nowrap">
          {{ data.item.shipping_address.address }}
        </span>
      </template>

      <!-- Column: Shipping -->
      <template #cell(tracking_number)="data">
        <span
          v-if="data.item.shipments.length"
          class="text-nowrap"
        >
          <a href="">{{ data.item.shipments[0].tracking_number }}</a>
        </span>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ formatDate(data.item.created_at) }}
        </span>
      </template>

      <!-- Column: Balance -->
      <template #cell(status)="data">
        <template>
          <b-badge
            v-if="data.item.status === 'paid'"
            pill
            variant="primary"
          >
            Pagado
          </b-badge>
          <b-badge
            v-if="data.item.status === 'pending'"
            pill
            variant="warning"
          >
            Sin pago
          </b-badge>
          <b-badge
            v-if="data.item.status === 'pending_approval'"
            pill
            variant="warning"
          >
            Pendiente de aprobación
          </b-badge>
          <b-badge
            v-if="data.item.status === 'shipped'"
            pill
            variant="light-success"
          >
            Enviado
          </b-badge>
          <b-badge
            v-if="data.item.status === 'completed'"
            pill
            variant="success"
          >
            Completada
          </b-badge>
          <b-badge
            v-if="data.item.status === 'cancelled'"
            pill
            variant="danger"
          >
            Cancelada
          </b-badge>
          <b-badge
            v-if="data.item.paymentMethod === 'conekta' && data.item.invoice"
            pill
            variant="primary"
            class="ml-2"
          >
            Facturado
          </b-badge>
          <b-badge
            v-if="data.item.paymentMethod === 'conekta' && !data.item.invoice"
            pill
            variant="secondary"
            class="ml-2"
          >
            No Facturado
          </b-badge>
        </template>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <b-button
            :id="`invoice-row-${data.item.id}-preview-icon`"
            variant="gradient-primary"
            @click="$router.push({ name: 'orders-show', params: { id: data.item.id }})"
          >
            Detalles
          </b-button>

        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Mostrando {{ getPerPage }} de {{ getTotalOrders }} pedidos</span>

        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="getTotalOrders"
            :per-page="getPerPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BTable, BLink,
  BBadge, BPagination, BButton,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import useInvoicesList from './useInvoiceList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BButton,

  },
  computed: {
    getOrders() {
      console.log(store.state.orders.orders.data)
      return store.state.orders.orders.data
    },
    getTotalOrders() {
      return store.state.orders.orders.total
    },
    getPerPage() {
      return store.state.orders.orders.per_page
    },
    getCurrentPage() {
      return store.state.orders.orders.current_page
    },

  },
  beforeMount() {
    store.dispatch('orders/index', { currentPage: 1 })
  },
  methods: {
    formatDate(date) {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: 'America/Mexico_City',
      }
      const parsedDate = new Date(date)
      const formatter = new Intl.DateTimeFormat('es', options)
      return formatter.format(parsedDate)
    },
  },
  setup() {
    const statusOptions = [
      'Downloaded',
      'Draft',
      'Paid',
      'Partial Payment',
      'Past Due',
    ]

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList()

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      statusOptions,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
